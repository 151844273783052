import { FC, ReactNode } from "react";
import { Button } from "../Button";

export interface TimelineProps {
  items: {
    rightContent: JSX.Element | string | ReactNode;
    leftContent?: JSX.Element | string | ReactNode;
    icon?: JSX.Element | ReactNode;
  }[];
  timelineIcon?: JSX.Element;
  leftContentWidth?: number;
  isEnd?: boolean;
  iconBackgroundColor?: string;
  iconContainerHeight?: string;
}

export const PrimaryTimeline: FC<TimelineProps> = ({
  items,
  timelineIcon,
  leftContentWidth = 0,
  isEnd = false,
  iconBackgroundColor,
  iconContainerHeight,
}) => {
  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8 relative">
        {items?.map((event, eventIdx) => (
          <li className="flex items-start" key={eventIdx}>
            <div
              style={{ direction: "rtl", width: leftContentWidth }}
              className="text-base pt-[6px] pr-[10px] text-gray-800 text-right "
            >
              {event?.leftContent}
            </div>
            <div className="w-[50%] relative">
              {eventIdx !== items.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-700"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    style={{ height: iconContainerHeight }}
                    className={`${
                      iconBackgroundColor ? iconBackgroundColor : "bg-gray-100"
                    } w-8 rounded-full flex items-center justify-center`}
                  >
                    {timelineIcon ? timelineIcon : event?.icon}
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div className="text-sm text-gray-900">
                    {event?.rightContent}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
        {isEnd && (
          <div className="absolute left-[21.5%] w-[120px] h-[40px] text-gray-900 bg-white rounded-lg shadow-md flex items-center justify-center">
            End of Drip
          </div>
        )}
      </ul>
    </div>
  );
};

PrimaryTimeline.defaultProps = {
  isEnd: false,
  iconContainerHeight: "24px",
  items: [
    {
      rightContent: <Button label="test" action="primary" />,
      leftContent: <Button label="test" action="primary" />,
    },
    {
      rightContent: "Advanced to phone screening by",
      // leftContent: <Button label="test" action="primary" />
    },
    {
      rightContent: "Completed phone screening with",
      leftContent: <Button label="test" action="primary" />,
    },
    {
      rightContent: <Button label="test" action="primary" />,
      // leftContent: 'sadjnaskjdnaskjdvasjdnsdghjoasildnbashjndaskjhdbvaskjndjahsbdas'
    },
    {
      rightContent: "Completed interview with",
      leftContent: "abchkjhfdsfsdfsdkud",
    },
  ]
};
