import { IconCheck } from '@tabler/icons'
import { FC } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Tag, previewFunc, UtilityModal, ModalBody, ToastNotification } from ".."
import badge from '../../assets/images/badge.png'
import { DELETE_CUSTOM_EMAIL_TEMPLATE } from "../../graphql/mutation/emailTemplate"
import { FormattedMessage } from 'react-intl';
import { getStringValue } from '../../utils'

interface TemplateCardProps {
  id: string
  siteId: string
  isActive: boolean
  name: string
  tags: Array<string>
  screenshotUrl: string
  premium: boolean
  hasNoEntitlement: boolean
  className?: string,
  width?: number,
  height?: number,
  designFormat?: string
  onClick: (id:string, designFormat?:string) => void
  live?: boolean
}

const DeleteCustomUserTemplateModal = ({ id, siteId, name, handleClose } : { id: string, siteId: string, name: string, handleClose: Function }) => {
  const [onDeleteTemplate] = useMutation(DELETE_CUSTOM_EMAIL_TEMPLATE, {
    variables: {
      input: {
        id: parseInt(id),
        siteId: parseInt(siteId)
      }
    },
    update(cache){
      cache.evict({
        id: cache.identify({
          __typename: 'EmailTemplate',
          id: parseInt(id),
        }),
      });
    },
    onCompleted: ({ deleteCustomEmailTemplate: { success = false } }) => {
      handleClose()
      ToastNotification({
        id: new Date(),
        icon: success ? "check" : "alert",
        color: success ? "success" : "danger",
        body: success ? "Template Deleted successfully" : "There is an Error. Please Try again Later"
      })
    }
  })
  
  return(
    <ModalBody
      action="danger" 
      title="Are you sure you want to delete?" 
      description={<p>This will delete your Custom Template <b>{name}</b> and this action cannot be reverted</p>}
      cancelButtonText="DISCARD"
      cancelButton={true}
      buttons={[{
        label:"Confirm", 
        action:"danger", 
        onClick: onDeleteTemplate
      }]}
    />
  )
}

export const TemplateCard:FC<TemplateCardProps> = (props) => {
  const {  id, isActive, name, className, onClick, tags, screenshotUrl, premium, hasNoEntitlement, siteId, designFormat, live = true, width = 244, height = 206 } = props

  const tagList = tags?.map((tag,index) => <Tag reactIntlId={tag} action="default" key={index} className="mr-2 whitespace-nowrap mt-2" labelClassName="capitalize" label={tag.split('_').join(" ")}/>)
  const isTemplateUserDefined = tags.includes('usertemplates')

  return (
    <div className={`mb-[24px] relative ${!live && 'opacity-50'}`}>
      <div className={`relative w-max group ${className}`} data-attribute={`template-${getStringValue(name)}`}>
        <div style={{ width, height, backgroundImage: `url(${screenshotUrl})`}} className={`${isActive && 'border-[1px] border-primary'} bg-center bg-contain bg-no-repeat rounded-[8px] bg-gray-400 mb-[8px]`}>
          <div className="group-hover:bg-black group-hover:opacity-60 w-full h-full rounded-lg"/>
          {
            isActive &&
            <div className="flex z-[2] items-center justify-center rounded-full bg-primary w-6 h-6 absolute -right-3 -top-3">
              <IconCheck size="16px" className="text-white" />
            </div>
          }
        </div>
        {
        premium && hasNoEntitlement
        && <div className="absolute top-0 left-0">
          <img className="w-[50%]" src={badge} alt="badge" />
        </div>
       }
        <div className="group-hover:flex hidden absolute inset-0 flex-col items-center justify-center">
          <Button reactIntlId="builder.template.use_template.button" label={"USE TEMPLATE"} className="mb-[10px]" onClick={() => onClick(id, designFormat)} dataAttribute="use-template" />
          <Button 
            type="outline" 
            label={"PREVIEW"}
            dataAttribute="preview-template-card"
            onClick={() => previewFunc({id, canEditName:!!siteId, title: name, url: screenshotUrl.replace('screenshot','preview_html'), hasPreview: true, isTemplatePreview: true, tags: tagList, onClick: () => onClick(id) })} 
            reactIntlId="builder.template.preview.button"
          />
          {
            isTemplateUserDefined &&
            <Button
              label={"Delete"}
              action="danger"
              className={"mt-[10px]"}
              onClick={() => UtilityModal({ body: ({ handleClose }) => 
                <DeleteCustomUserTemplateModal siteId={siteId} id={id} name={name} handleClose={handleClose}/> })}
              reactIntlId="builder.template.delete.button"
            />
          }
        </div>
      </div>
      <div className="flex items-center">
        <p className="text-lg font-medium overflow-ellipsis whitespace-nowrap overflow-hidden w-[244px] text-secondary mr-[14px]">
          {name || `User Template`}
        </p>
      </div>
    </div>
  )
}