import { IconDeviceDesktop, IconDeviceMobile, IconDots, IconX } from "@tabler/icons";
import { FC, ReactNode, memo, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Spinner, UtilityModal, Input, Tag } from "..";
import { ReactComponent as EmptyPreviewSvg } from "../../assets/svg/EmptyPreviewSvg.svg";
import { IconCheck, IconEdit } from '@tabler/icons';
import { ChangeEvent } from 'react';
import { UpdateEmail, UpdateEmailTemplate } from "../../modules/Campaigns/graphql/mutations/email";
import { useMutation } from '@apollo/client'
import { showToastNotification } from "../../utils";



interface PreviewFuncParams {
  title: string
  url: string
  link?: string
  id: string | number 
  history?: any
  hasPreview?: boolean
  widgetTypeName?: string
  isBarPositionBottom?: boolean
  isTemplatePreview?: boolean
  tags?: Array<any>
  content?: Array<{ label: string, value: ReactNode | string }>
  onClick?: any
  canEditName?: boolean
}

type contentType = Array<{
  label: string
  value: ReactNode | string | string[]
}>

interface PreviewBodyProps {
  url: string
  title: string
  id: string | number
  hasPreview?: boolean
  link?: string
  content?: contentType
  history?: any
  widgetTypeName?: string
  isBarPositionBottom?: boolean
  handleClose: () => void
  isTemplatePreview?: boolean
  tags?: Array<any>
  onClick?: any
  canEditName?: boolean
}


export interface TemplateNameProps {
  id: number | string, 
  name: string, 
  canEditName?: boolean
}

const TemplateName= ({ id, name, canEditName }:TemplateNameProps) => {

  const [title, setTitle] = useState(name)
  const [error, setError] = useState(false)
  const [editToggle, setEditToggle] = useState(false)

  const [updateEmail] = useMutation(UpdateEmailTemplate, {refetchQueries: ['fetchDripCampaign', 'fetchJourney'] })



  const handleSubmit = async () => {
    if (title.trim()) {
      try {
        await updateEmail({ variables: { name: title.trim(), id } });
        setEditToggle(false);
        setError(false);
      } catch (err) {
        showToastNotification({
          body: "Failed to update email",
          icon: "alert",
          color: "danger",
        });
      }
    } else {
      setError(true);
      setTitle(title.trim());
    }
  };

  return (
    <div className={`flex items-center`}>
      {!editToggle ? <div className="flex items-center gap-x-3 mb-2">
        <h4 className="text-3lg font-normal break-all whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[308px]">
       {title}</h4>
       {canEditName &&  
        <IconEdit data-attribute="icon-campaign-edit-name" stroke="1px" className="cursor-pointer" size="20px" onClick={() => setEditToggle(!editToggle)} />
       }
      </div> 
        :
        <div className={`flex gap-x-2 items-center mb-1 mt-1`}>
          <Input 
            autoFocus={true}
            fontSize="text-lg"
            action="secondary"
            error={(!title || error) ? 'required' : undefined}
            className={`${title && 'border border-primary'} text-secondary font-normal h-[25px] `}
            value={title} 
            onEnterCallback={handleSubmit}
            dataAttribute="campaign-edit-name"
            onChange={(e:ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)} />
            <div className="flex z-10">
          <Tag 
            action="default" 
            icon={<IconCheck className="w-[16px]" />} 
            className="bg-gray-500 cursor-pointer mx-[4px] h-[30px] w-[30px]"  
            onClick={handleSubmit}  
            dataAttribute='campaign-edit-check'
            />
          <Tag 
            action="default" 
            icon={<IconX className="w-[16px]" />} 
            className="bg-gray-500 cursor-pointer h-[30px] w-[30px]" 
            onClick={() => {
              setTitle(name)
              setEditToggle(false)
            }} 
            />
        </div> 
        </div>
      }
      </div>
  )
}

const PreviewContent: FC<{ content?: contentType }> = memo(({
  content
}) => {
  return <>
  {
  content?.map(({ label, value }, index) => {

  const hasIntegartionSection = label?.toLowerCase().includes('integration')

   return <div key={index} className={`flex justify-start mt-[20px] flex-1 ${(label?.toLowerCase()?.includes('feature')) ? "items-center" : "items-baseline"}`}>
      <h4 className="w-[40%] text-sm text-secondary font-medium">{label}</h4>
      { 
        hasIntegartionSection 
        ? 
        <div className="grid grid-cols-4 place-items-center gap-1 self-end">
        { 
         Array.isArray(value) && value?.map((integration) =>
          <div className="w-[100%]">
            <img 
            className="p-[4px] w-9 max-w-20 h-8 max-h-9 border border-gray-600 rounded" 
            src={require(`../../assets/svg/IntegrationLogos/${integration?.toString().toLowerCase()}.svg`)?.default} 
            alt={integration as string}
            />
          </div>)
          }
        </div> 
        :
      <div className="w-[60%] text-sm text-gray-900 font-light" >{value}</div>
      }
    </div>
    }
  )}
</>
})


const PreviewBody: FC<PreviewBodyProps> = ({
  url,
  title,
  content,
  hasPreview,
  history,
  link,
  widgetTypeName,
  isBarPositionBottom,
  handleClose,
  isTemplatePreview,
  tags,
  onClick,
  canEditName,
  id,
  }) => {
    
  const [mobileView, setMobileView] = useState(false)
  const [loader, setLoader] = useState(true)
  const isWidget = link?.includes('widgets')

  useEffect(() => {
    return () => window?.track_mixpanel('campaigns:preview:close')
  },[])
  
  // const iframeRef = useRef<HTMLIFrameElement | null>(null)
  //TODO - some problem with rails...
  const resizeIframe = () => {
    // if (!iframeRef || !iframeRef.current || !iframeRef.current.contentWindow) return
    // iframeRef.current.style.height = `${iframeRef.current.contentWindow.document.body.scrollHeight}px`;
    setLoader(false)
  }

  
  const maxWidth = (widgetTypeName === 'Cover' || widgetTypeName === 'Topbar') ? '100%' : widgetTypeName === 'Sidebar' ? '300px' : '70%';
  const verticalAlign = widgetTypeName === 'Topbar' ? isBarPositionBottom ? 'flex-end' : 'flex-start' : 'center';

  return (
    <div className="p-[80px] flex justify-center items-center relative">
      <IconX size="20px" onClick={handleClose} className="absolute top-[25px] right-[25px] cursor-pointer" />
      <div className={`min-w-[656px] h-[554px] flex flex-col justify-center items-center'}`} style={{alignItems: 'center'}}>
        {
        mobileView ?
        <div className="w-[226px] h-[425px] border border-gray-600 rounded-[24px] flex flex-col items-center relative">
          <div className="flex items-center mt-[30px]">
            <div className="w-[10px] h-[10px] rounded-full border border-gray-600 mr-[8px]"></div>
            <div className="w-[56px] h-[8px] rounded-full border border-gray-600"></div>
          </div>
          <div className="h-[150%] scale-50 absolute top-[-99px]">
            {!hasPreview ? <div className="h-full w-[414px] grid place-items-center">
            <div>
              <EmptyPreviewSvg  className="text-primary"/>
              <div className="text-gray-800 ml-2">No preview found.</div>
            </div>
            </div> 
            :
            <>
            {loader && <div className="absolute top-[45%] right-[40%] z-10"><Spinner width={60} height={60} /></div>}
            <iframe 
              // ref={iframeRef}
              onLoad={resizeIframe}
              loading="eager" 
              title="full screen preview" 
              className={`h-full w-[414px] ${loader && 'blur-sm'}`} 
              style={{transition:"filter 1000ms"}}
              src={url} 
              frameBorder="0"
              ></iframe>
              </>
            }
          <div className="h-[40px] w-[40px] rounded-full border-2 border-gray-800 my-[28px] mx-auto"></div>
          </div>
        </div> 
        :
        <>
        <div className="w-[656px] h-[27px] border text-gray-600 border-gray-600 rounded-tr-lg rounded-tl-lg">
          <IconDots className="ml-[8px]" />
        </div>
        {
        !hasPreview ? 
        <div className="h-full w-full border border-t-0 border-gray-600 grid place-items-center">
          <div>
            <EmptyPreviewSvg  className="text-primary"/>
            <div className="text-gray-800 ml-2">No preview found.</div>
          </div>
          </div> : 
          <div style={{ alignItems: verticalAlign, width: !isWidget ? '200%' : '', transform: !isWidget ? 'scale(0.5)' : '' }} className="h-full w-full flex relative items-center justify-center border border-t-0 border-gray-600">
              {loader && <div className="absolute top-[15%] right-[47%] z-10"><Spinner width={50} height={50} /></div>}
            <iframe 
              // ref={iframeRef}
              onLoad={resizeIframe}
              loading="eager" 
              title="full screen preview" 
              className={`h-full w-full ${loader && 'blur-sm'}`}
              style={{ maxWidth: isWidget ? maxWidth : '100%', transition:"filter 1000ms", height: !isWidget ? '200%' : '' }}
              src={url} 
              frameBorder="0"
              ></iframe>
          </div>
        }
        </>
        }
      <div className="w-[1px] absolute h-[580px] top-[82px] right-[34%] bg-gray-300"></div>
      </div>
      <div className="w-full h-full pl-[70px] self-start">
        <div className="flex mb-[30px]">
          <IconDeviceDesktop size="20px" onClick={() => setMobileView(false)} className={`cursor-pointer mr-[4px] ${!mobileView ? 'text-gray-900':'text-gray-800'}`} />
          <IconDeviceMobile size="20px" onClick={() => setMobileView(true)} className={`cursor-pointer ${mobileView ? 'text-gray-900':'text-gray-800'}`} />
        </div>

        <TemplateName name={title} id={id} canEditName={canEditName} />
        {/* <div className="text-lg text-gray-800 mt-[14px] font-light">{!mobileView ? '656 × 900' : '320 x 404'} PX</div> */}
        {content && <PreviewContent content={content} />}
        {tags && <div className="flex flex-wrap">{tags}</div>}
        {isTemplatePreview && <div className="mt-4 text-sm text-gray-800">
          <FormattedMessage id="builder.template.preview.modal.disclaimer" defaultMessage={'This template is designed to be fully responsive. To start editing, click the button below.'} />
        </div>}
        {(link || onClick) && <Button 
          className={`${isTemplatePreview ? 'mt-4':'mt-10'} w-full h-[40px] flex justify-center`} 
          label={`${link?.includes('analytics') ? 'View Report' : `${isTemplatePreview ? 'Use' : 'Edit'} this template`}`}
          reactIntlId={`builder.template.preview.modal.${link?.includes('analytics') ? 'view_report_button' : `${isTemplatePreview ? 'use_button' : 'edit_button'}`}`}
          type="default" 
          action="primary" 
          onClick={() => {
            link ? history.push(link) : onClick && onClick()
            handleClose()
           }
          } 
          />}
      </div>
    </div>
  )
}


export const previewFunc = (params : PreviewFuncParams) => {
  window?.track_mixpanel('campaigns:preview:open')
  UtilityModal({ 
    id: "preview-modal",
    width: 1194,
    height: 730,
    body: props => <PreviewBody {...props} {...params} />
  })
}