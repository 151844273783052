import PropTypes from "prop-types";

import { DropDownIcon } from "./DropDownIcon";
import { FormattedMessage } from "react-intl"
import { Dropdown } from "../../components"

export const Button = (props) => {
  const {
    action,
    className,
    disabled,
    dropDown,
    dropDownOptions,
    dropDownCallback,
    icon,
    label,
    height,
    width,
    fontSize,
    type,
    onClick,
    setOpen,
    labelStyles,
    hasShadow,
    isUppercase = true,
    tabIndex,
    id,
    dataAttribute,
    iconPosition,
    reactIntlId,
    reactIntValues
  } = props;

  const styles = {
    type : {
      default: {
        global: `font-medium ${icon ? 'px-[16px]' : 'px-[24px]'}`,
        action: {
          warning: `bg-warning text-white ${!disabled && 'hover:bg-warning-dark'}`,
          primary: `bg-primary text-white ${!disabled && 'hover:bg-primary-dark'}`,
          secondary: `bg-primary-lighter text-primary`,
          danger: `bg-danger text-white ${!disabled && 'hover:bg-danger-dark'}`,
          default: `text-gray-900 text-white bg-gray-400 border-2 border-gray-400 ${!disabled && 'hover:bg-white hover:border-2'}`,
          transparent: 'text-white bg-transparent border-2 border-white'
        }
      },
      outline: {
        global: `font-medium bg-white border-2 ${icon ? 'px-[14px]' : 'px-[22px]'}`,
        action: {
          primary: `text-primary border-primary ${!disabled && 'hover:text-white hover:bg-primary'}`,
          danger: `text-danger ${!disabled && 'hover:text-white hover:bg-danger'}`,
          default:`bg-gray-200 text-gray-900 ${!disabled && 'hover:text-white hover:bg-gray-900'}`,
        }
      },
      link: {
        global: `font-normal ${icon ? 'px-[14px]' : 'px-[22px]'}`,
        action: {
          primary: 'text-primary',
          danger: 'text-danger'
        }
      }
    }
  }

  const disabledStyles = disabled ? "opacity-50 cursor-not-allowed pointer-events-none" : 'cursor-pointer'
  const { global, action: actionStyles } = styles.type[type]
  const typeStyles = global.concat(` ${actionStyles[action]}`)

  const buttonStyles = {
    height,
    fontSize
  };

  if (width) buttonStyles['width'] = width;

  return (
    // <div
    //   onClick={onClick}
    //   style={buttonStyles}
    //   className={`flex items-stretch w-max font-rubik rounded uppercase relative ${typeStyles} ${disabledStyles} ${className}`}
    // >
    <div className="relative">
      <button
        type="button"
        onClick={onClick}
        id={id}
        className={`${disabled && 'cursor-not-allowed'} ${dropDown && 'mr-[26px]'} rounded-[4px] flex items-center ${isUppercase && 'uppercase'} ${typeStyles} ${disabledStyles} ${className}`}
        {...hasShadow && { style: { boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)" }}}
        style={buttonStyles}
        {...(tabIndex && {tabIndex})}
        {...(
          dataAttribute && {
            "data-attribute": `button-${dataAttribute}`
          }
        )}
      >
        <div className={`flex items-center justify-center ${iconPosition === 'after' && 'flex-row-reverse'}`}>
          {
            icon &&
            <div className={`${label ? iconPosition === 'after' ? 'ml-[8px]' : 'mr-[8px]' : ''}`}>
              {icon}
            </div>
          }
          <div className={`text-sm ${labelStyles}`}>
            {
              reactIntlId ? 
              <FormattedMessage id={reactIntlId} defaultMessage={label} values={reactIntValues} /> :
              label
            }
          </div>
        </div>
      </button>
      {dropDown && (
        // <Menu.Button>
        <>
        <div onClick={setOpen} className={`items-stretch absolute top-0 right-0 bottom-0 cursor-pointer bg-primary rounded-r-[4px]`}>
          <Dropdown 
            alignLeft={true}
            component={
              <div className="mb-[5px] relative h-[40px]">
                <div className="bg-black opacity-10 absolute inset-0 rounded-r-[4px] max-h-[40px]" />
                <div className="flex items-center justify-center w-full h-full text-white px-[12px]">
                  { dropDown?.icon ? dropDown?.icon : <DropDownIcon />}            
                </div>
              </div>
            }
            width="200px"
            options={dropDownOptions}
            callback={dropDownCallback}
        />
        </div>
        </>
        // </Menu.Button>
      )}
      </div>
    // </div>
  );
};

Button.propTypes = {
  isUppercase: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onClick: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  action: PropTypes.oneOf(["primary", "danger","default"]),
  type: PropTypes.oneOf(["default", "outline", "link"]),
  disabled: PropTypes.bool,
  dropDown: PropTypes.object,
  icon: PropTypes.object,
  fontSize: PropTypes.number,
  labelStyles: PropTypes.string,
  hasShadow: PropTypes.bool,
  dropDownOptions: PropTypes.array,
  dropDownCallback: PropTypes.func,
  id: PropTypes.string,
  iconPosition: PropTypes.string,
  dataAttribute: PropTypes.string,
  reactIntlId: PropTypes.string,
  reactIntValues: PropTypes.object,
};

Button.defaultProps = {
  isUppercase: true,
  className: '',
  icon: undefined,
  label: "",
  onClick: undefined,
  height: 32,
  type: "default",
  action: "primary",
  disabled: false,
  dropDown: undefined,
  reactIntlId: "",
  fontSize: 12,
  labelStyles: "",
  hasShadow: false,
  id: '',
  iconPosition: 'before',
  reactIntValues: {}
};
