import React from "react";
import { FormattedMessage } from "react-intl";

export interface SettingsCardProps {
  title: string;
  description: string;
  icon?: React.ReactElement;
  cn?: string;
  path?: string;
  onClick?: (path: string | undefined) => {};
  reactIntlTitleId?: string
  reactIntlDescriptionId?: string
}

const SettingsCard: React.FC<SettingsCardProps> = ({
  title,
  description,
  icon,
  path,
  onClick,
  reactIntlTitleId,
  reactIntlDescriptionId
}) => {

  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick(path);
    }
  };

  return (
    <div
      className="group h-[137px] w-[332px] pt-[23px] px-[24px] flex bg-gray-100 hover:bg-primary-lighter rounded-[4px] cursor-pointer"
      onClick={handleClick}
    >
      <div className="h-[20px] w-[20px]">{icon && icon}</div>
      <div className="ml-[18px]">
        <div className="text-lg font-normal mb-[8px] group-hover:text-primary">
          {
            reactIntlTitleId ?
            <FormattedMessage id={reactIntlTitleId} defaultMessage={title}/> : title
          }
        </div>
        <div className="text-sm2 font-normal mb-[8px] text-gray-800 leading-[20px]">
          {
            reactIntlDescriptionId ?
            <FormattedMessage id={reactIntlDescriptionId} defaultMessage={description}/> : description
          }
        </div>
      </div>
    </div>
  );
};

SettingsCard.defaultProps = {
  title: "",
  description: "",
  reactIntlTitleId: "",
  reactIntlDescriptionId: ""
};

export default SettingsCard;
