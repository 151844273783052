import { Button } from ".."

const UpgradeContainer = (props) => {
  const { errors, handleUpdate } = props

  return (
    <div>
      <div className="py-[16px] px-[16px] border border-danger bg-danger-light rounded-[8px] flex justify-between w-full">
        <div className="ml-[17px] flex flex-col">
          <div className="text-sm text-danger mb-[8px] font-medium">Upgrade Required</div>
          <div className="text-sm font-normal text-secondary">You are using the following features that are not available in your plan:</div>
          <div className="my-[6px]">
            {
              errors?.map((item,index) => {
                return (
                  <div key={index} className="flex items-center">
                    {item?.icon && item?.icon()}
                    <div className="ml-[8px] font-medium text-danger text-sm">{item?.error}</div>
                  </div>
                )
              })
            }
          </div>
          <div className="text-sm font-normal text-secondary">Please upgrade your plan to activate the form.</div>
        </div>
      </div>
      <Button className="mt-[16px]" height={32} label={'UPGRADE ACCOUNT'} action={'danger'} onClick={() => window?.pricing?.show()}/>
    </div>
  )
}

export { UpgradeContainer }