import { useState } from "react"
import {
  Button,
  UtilityModal,
  Input,
  ModalBody,
} from "../"
import { useMutation } from "@apollo/client"
import { CREATE_LIST } from '../../graphql/mutation/list'
import { FETCH_LISTS } from "../../graphql/query/list"
import { FormattedMessage } from 'react-intl';

const Body = (props) => {
  const { onChange, siteId } = props
  const [name, setName] = useState('')
  const [createList, { loading } ] = useMutation(CREATE_LIST)

  return (
    <ModalBody
      action="primary"
      title={<FormattedMessage id="builder.new_list.modal.title" defaultMessage="Create New List" />}
      cancelButtonText="Cancel"
      buttons={[
        {
          label: "Confirm",
          reactIntlComponent: <FormattedMessage id="builder.new_list.modal.confirm" defaultMessage="Confirm" />,
          action: "primary",
          type: "default",
          dataAttribute: "list-save",
          disabled: name.length === 0 || loading,
          onClick: () => createList({ variables: { siteId, name }, update(cache, { data: { createList: { list } } }) {
            const { lists = [] } =  cache.readQuery({ query: FETCH_LISTS,  variables: { siteId } })
            cache.writeQuery({ query: FETCH_LISTS, variables: { siteId }, data: { lists: [...lists, list] } })
          } }).then((response) => {
            const { data: { createList: { success, list } = {}} = {}} = response || {}
            const { id } = list || {}
            success && onChange(id)
          }),
        },
      ]}
      width="494px"
      handleClose={props.handleClose}
    >
      <Input label="Name" reactIntlId={'builder.new_list.modal.input.label'} value={name} maxLength={"50"} onChange={(e) => {
        setName(e.target.value)
      }} className="w-full" dataAttribute={"list-name"}/>
    </ModalBody>
  )
}

const CreateNewList = (props) => {

  return (
    <>
      <Button
        height={30}
        dataAttribute="create-new-list"
        className="ml-[10px]"
        label="+ New List"
        reactIntlComponent={ <FormattedMessage id="builder.new_list.button" defaultMessage={"+ New List"} /> }
        onClick={() => {
          UtilityModal({
            body: (bProps) => < Body {...bProps} {...props} />
          });
        }}
      />
    </>
  );
};

export default CreateNewList
