export const suppressionList = [
  'gmail.con',
  'gmial.com',
  'gmale.com',
  'gmali.com',
  'gnail.com',
  'botmail.com',
  'hotnail.com',
  'hotnail.con',
  'hotmail.con',
  'hotmale.com',
  'msn.con',
  'aol.con',
  'yahoo.vom',
  'yaho.com',
  'yahoo.con',
  'gmail.von',
  'botmail.von',
  'hotmail.von',
  'aol.von',
  'abc.com',
  'test.com',
  'testing.com',
  'bot.com',
  '.nett',
]