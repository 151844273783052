import PropTypes from "prop-types";
import { IconLayout } from "@tabler/icons";
import { useLocation } from 'react-router-dom'

const SubMenuItem = (props) => {
  const { label = " ", icon, selected, type } = props;
  const location = useLocation()

  const activeCondition = location.search && location.search?.split('type')[1]?.includes(type)

  return (
    <>
      <div className={`h-[36px] mb-[4px] flex items-center text-secondary cursor-pointer hover:text-primary ${activeCondition && 'text-primary'}`}>
        {activeCondition && (
          <div className="h-[36px] w-[2px] rounded-[4px] bg-primary"></div>
        )}
        <div className={`flex items-center uppercase ${activeCondition ? 'ml-[15.5px]' : 'ml-[17.5px] '}`}>
          {
            icon && <span className={`mr-[11.33px] ${activeCondition && 'text-primary'}`}>{icon}</span>
          }
          {label && <div className="text-[13px] font-normal">{label}</div>}
        </div>
      </div>
    </>
  );
};

SubMenuItem.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.bool,
  selectedSubmenuItem: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.object,
};

SubMenuItem.defaultProps = {
  icon: <IconLayout />,
  label: "SubMenu Item",
  onClick: undefined,
  selected: false,
};

export default SubMenuItem;
